<template>
  <section class="order-wrapper">
    <card title="订单管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        style="right: 170px"
        @click="downLoadXls(0)"
        >批量导出(无发件人)</el-button
      >
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="downLoadXls(1)"
        >批量导出(有发件人)</el-button
      >
      <el-table :data="orderList" border>
        <el-table-column
          label="订单号"
          prop="oid"
          align="center"
        ></el-table-column>
        <el-table-column
          label="用户手机号"
          prop="tel"
          align="center"
          width="140"
        ></el-table-column>
        <el-table-column label="商品" align="center">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.opLinks" :key="index"
              >{{ index + 1 }}. {{ item.pName }}<br
            /></span>
          </template>
        </el-table-column>
        <el-table-column
          label="金额"
          prop="money"
          align="center"
          width="110"
          :formatter="priceFormatter"
        ></el-table-column>
        <el-table-column
          label="订单状态"
          prop="status"
          align="center"
          width="110"
        ></el-table-column>
        <el-table-column
          label="物流公司"
          prop="type"
          align="center"
        ></el-table-column>
        <el-table-column
          label="运单号"
          prop="waybill"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="210">
          <template slot-scope="scope">
            <el-button size="mini" @click="addDetails(scope.$index, scope.row)"
              >添加物流信息</el-button
            >
            <el-button size="mini" @click="viewDetails(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next,jumper"
        :total="totalCount"
        style="margin: 20px 0 0 30px"
      >
      </el-pagination>
    </card>
    <el-dialog title="运单信息" :visible.sync="dialogFormVisible" width="50%">
      <el-form
        :model="logistics"
        label-position="left"
        class="logistics-form"
        ref="form"
      >
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="10"
            ><el-form-item label="商品名称:" label-width="90px">
              <el-input
                v-model="logistics.cargo.detail_list[0].name"
                autocomplete="off"
                class="form-input"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="10"
            ><el-form-item label="商品数量:" label-width="90px">
              <el-input
                v-model="logistics.cargo.detail_list[0].count"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="10"
            ><el-form-item label="包裹数量:" label-width="90px">
              <el-input
                v-model="logistics.cargo.count"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="10"
            ><el-form-item label="包裹重量:" label-width="90px">
              <el-input
                v-model="logistics.cargo.weight"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="10"
            ><el-form-item label="包裹长度:" label-width="90px">
              <el-input
                v-model="logistics.cargo.space_x"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="10"
            ><el-form-item label="包裹宽度:" label-width="90px">
              <el-input
                v-model="logistics.cargo.space_y"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row class="row-bg">
          <el-col :span="10"
            ><el-form-item label="包裹高度:" label-width="90px">
              <el-input
                v-model="logistics.cargo.space_z"
                autocomplete="off"
                class="form-input"
                type="number"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitLogistics()">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import card from "../../components/card";

export default {
  components: {
    card
  },
  data() {
    return {
      // orderList: [
      //   {
      //     orderNum: "123456789",
      //     phone: "18812345678",
      //     commodity: ["A奶粉 X1", "B奶粉 X2"],
      //     price: 123,
      //     state: "已完成",
      //     waybillNum: "123456789"
      //   }
      // ],
      orderList: [],
      // 默认显示第几页
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0,
      logistics: {
        cargo: {
          detail_list: [
            {
              count: 0,
              name: ""
            }
          ],
          count: 0,
          space_x: 0,
          space_y: 0,
          space_z: 0,
          weight: 0
        },
        insured: {
          insured_value: 0,
          use_insured: 0
        },
        orderId: "",
        service: {
          service_name: "标准快件",
          service_type: 0
        }
      },
      dialogFormVisible: false
    };
  },
  methods: {
    viewDetails(index, row) {
      this.$router.push({
        name: "checkorder",
        query: { uid: row.uid, oid: row.oid }
      });
    },
    addDetails(index, row) {
      this.dialogFormVisible = true;
      this.logistics = this.$options.data().logistics;
      this.logistics.orderId = row.oid;
    },
    submitLogistics() {
      this.$axios({
        url: "/back/order/addLogistics",
        method: "post",
        transformRequest: [
          function(data) {
            return JSON.stringify(data);
          }
        ],
        data: this.logistics,
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加物流信息成功"
            });
          } else {
            this.$message({
              type: "error",
              message: "添加物流信息失败"
            });
          }
          this.dialogFormVisible = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
    async downLoadXls(type) {
      try {
        const { data } = await this.$axios({
          url: "back/order/getDoc",
          params: {
            type
          }
        });
        if (data.code !== 200)
          return this.$message({
            type: "error",
            message: "文件获取失败"
          });
        const a = document.createElement("a");
        a.download = type ? "订单(有发件人).xls" : "订单(无发件人).xls";
        console.log(a.href)
        a.href = `https://${data.data}`;
        console.log(a.href)
        a.click();
      } catch (err) {
        console.log(err);
      }
    },
    priceFormatter(row, col) {
      if (Number(row[col.property]))
        return "￥" + Number(row[col.property]).toFixed(1);
      else return "/";
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getOrder();
    },
    getOrder() {
      this.$axios({
        url: "/back/order/order/getAllOrder",
        params: {
          page: this.currentPage
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.PageSize = res.data.data.pageSize;
            this.totalCount = res.data.data.total;
            this.orderList = res.data.data.list;
          } else {
            this.$message({
              type: "error",
              message: "订单信息获取失败"
            });
          }
          setTimeout(() => {
            this.$openLoading().close();
          }, 200);
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "订单信息获取失败"
          });
          setTimeout(() => {
            this.$openLoading().close();
          }, 200);
        });
    }
  },
  created() {
    this.$openLoading();
    this.getOrder();
  }
};
</script>

<style lang="less">
.order-wrapper {
  .card-btn {
    position: absolute;
    right: 20px;
    top: 6px;
  }
  .tag-wrapper {
    span {
      margin: 10px auto;
    }
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.logistics-form {
  margin: 0 auto;
  width: 88%;

  .form-input {
    margin: 0 auto;
  }
}
</style>
